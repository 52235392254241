.mark {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 60%;
  }
}

.content {
  text-align: center;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 0.75rem 1rem;
  & + & {
    border-top: 1px solid var(--color-border-field3);
  }
}

.title {
  padding: 0.5rem 1rem 0;
  color: #4fb1eb;
}
